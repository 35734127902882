import React from 'react'
import Footer from '../../Footer/Footer';
import Header from '../../Header/Header';
import Navbar from '../../Navbar/Navbar';
import UserListBody from './UserListBody';

function UserList() {
  return (
    <>
        <Navbar />
        <Header />
        <UserListBody />
        <Footer />
    </>
  )
}

export default UserList;