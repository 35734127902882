import React, { useEffect } from 'react'

function NotInNotFound() {

  useEffect(() => {
    window.location.replace("/login");
  },[]);

  return (
    <div></div>
  )
}
export default NotInNotFound;