import axios from 'axios';
import React, { Component } from 'react'
import { API } from '../../../API/Api';
import UserContext from '../../../context/UserContext';
import { Link } from 'react-router-dom';
import ListBody from '../../ListPart/ListBody';
import ListHeader from '../../ListPart/ListHeader';

class UserListBody extends Component {
    static contextType = UserContext;

    state = {
        "users": [],
        "successMessage":""
    }

    componentDidMount = async () => {

        await axios.get(API + "/user/list")
            .then(response => {
                this.setState({

                    users: response.data.filter(user => user._id !== this.context.userId).sort((first, second) => {
                        if (first.name.toLowerCase() < second.name.toLowerCase()) {
                            return -1;
                        }

                        return 0;
                    })
                })
            }).catch(() => {
                window.location.replace("/login")
            });
    }


    onClickDelete = async(id) => {
       await axios.delete(API + `/user/delete/${id}`)
        .then(res => {
            this.setState({
                ...this.state,
                users: this.state.users.filter(user => user._id !== id),
                successMessage: res.data.successMessage
            })
        })
    }

    render() {
        const data = {
            columns: [
                {
                    label: 'Ad',
                    field: 'name',
                    sort: 'asc',
                },
                {
                    label: 'Soyad',
                    field: 'surname',
                    sort: 'asc',
                },
                {
                    label: 'Kullanıcı Adı',
                    field: 'username',
                    sort: 'asc',
                },
                {
                    label: 'Email',
                    field: 'email',
                    sort: 'asc',
                },
                {
                    label: 'Telefon',
                    field: 'phone',
                    sort: 'asc',
                },
                {
                    label: 'İşlemler',
                    field: 'operation',
                    sort: 'asc',
                }
               
            ],
            rows: this.state.users.map(user => {
                return {
                    name:user.name,
                    surname:user.surname,
                    username:user.userName,
                    email:user.email,
                    phone:user.phone,
                    operation: <>

                    <button className='btn delete-button me-2' onClick={this.onClickDelete.bind(this, user._id)}>
                        Sil
                    </button>

                    <Link to={"/user/update/" + user._id} className='btn update-button'>
                        Kullanıcı Güncelle
                    </Link>
                </>
                }
            })
        }
        return (
            <div className='datatable-page'>
                <Link to="/users/add" className='btn add-button'>
                    Kullanıcı Ekle
                </Link>
                <div className='user-list'>
                        <ListHeader header="Kullanıcı Listesi" />
                        {this.state.successMessage ? <div className="alert alert-success response-message">{this.state.successMessage}</div> : null}
                        <ListBody data={data} />
                </div>
            </div>
        )
    }
}
export default UserListBody;