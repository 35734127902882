import * as constants from './constants';

export const setIsLogin = isLogin => ({
    type: constants.IS_LOGIN,
    payload: isLogin
});

export const setUserAuthority = userAuthority => ({
    type: constants.SET_USER_AUTHORITY,
    payload: userAuthority
});

export const setPastMaintenancesFilterList = filterList => ({
    type: constants.SET_FILTER_PAST_MAINTENANCE_LIST,
    payload: filterList
});

export const setDefaultValues = () => ({
    type: constants.SET_DEFAULT_VALUES
});