import axios from 'axios';
import React, { useState } from 'react'
import * as api from '../../../API/Api'
import TMS from '../../../Images/tms-logo-black.png';

function PasswordRemember() {

    const [userName, setUserName] = useState("");
    const [statusMessage, setStatusMessage] = useState("");
    const [alertClass, setAlertClass] = useState("alert-warning");

    const onChangeUserName = e => {
        setUserName(e.target.value);
        setStatusMessage("");
        setAlertClass("alert-warning");
    };

    const onClickPasswordRemember = async (e) => {
        e.preventDefault();
        setStatusMessage("Lütfen bekleyiniz")
        await axios.put(api.API + "/user/password-remember", {
            userName
        }).then(res => {
            setStatusMessage(res.data.successMessage);
            setAlertClass("alert-success");
        }
        )
            .catch(err => {
                if (err.response)
                    if (err.response.data.errorMessage) {
                        setStatusMessage(err.response.data.errorMessage);
                        setAlertClass("alert-danger");
                    }
            })
    };

    return (
        <div className="password-remember">
            <form onSubmit={onClickPasswordRemember}>
                <label htmlFor="chk" aria-hidden="true">
                    Şifremi Unuttum
                </label>
                <input type="text" name="userName" placeholder="Kullanıcı Adı" value={userName} onChange={onChangeUserName} required />
                {
                    statusMessage ? <div className={"alert " + alertClass + " text-center"} role="alert">
                        {statusMessage}
                    </div> : null
                }
                <button type='onSubmit'>Yeni Şifre Yolla</button>
            </form>
            <div className='logo-div'>
                <img src={TMS} alt="loginimg" />
            </div>
        </div>

    )
}

export default PasswordRemember;