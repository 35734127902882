import React from 'react'
import Navbar from '../../Navbar/Navbar';
import Header from '../../Header/Header';
import PastMaintenancesListBody from './PastMaintenancesListBody';
import Footer from '../../Footer/Footer';

function PastMaintenancesList() {
  return (
    <>
        <Navbar />
        <Header />
        <PastMaintenancesListBody />
        <Footer />
    </>
  )
}
export default PastMaintenancesList;