import React from 'react';
import PopupMaintenanceCard from './PopupMaintenanceCard';

function CardPopup(props) {
    const { _id, trainInformation, userInformation, maintenancesContents } = props.maintenance;

    return (
        <div className="modal fade" id={props.maintenance ? "o" + _id : ""} role="dialog" aria-labelledby="exampleModalLabel">
            <div className="modal-dialog modal-lg">
                <div className="modal-content m-0" style={{ paddingTop: "20px" }}>
                    <div className="modal-header">
                        <span>
                            <i className="fa fa-subway me-1 ms-0" style={{ fontSize: "20px" }} />
                            {trainInformation[0].trainName} Bakım Detayı
                        </span>

                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                        </button>
                    </div>

                    <div className="modal-body p-0" style={{ width: "95%", margin: "auto" }}>
                        {
                            props.maintenance ? <div className='p-3'>

                                <p><b>Tren Numarası : </b>{trainInformation[0].trainName}</p>
                                <p><b>Kullanıcı Adı : </b>{userInformation[0].name + " " + userInformation[0].surname}</p>
                                <p><b>Açıklama : </b>{trainInformation[0].expaliningMaintenance}</p>
                                <div className='card-popup-body-row'>
                                    <PopupMaintenanceCard name="Servis Bakımı" date={maintenancesContents[3].date} km={maintenancesContents[3].km} explaining={maintenancesContents[3].explaining} />
                                    <PopupMaintenanceCard name="KB1 Bakımı" date={maintenancesContents[0].date} km={maintenancesContents[0].km} explaining={maintenancesContents[0].explaining} />
                                </div>
                                <div className='card-popup-body-row'>
                                    <PopupMaintenanceCard name="KB2 Bakımı" date={maintenancesContents[1].date} km={maintenancesContents[1].km} explaining={maintenancesContents[1].explaining} />
                                    <PopupMaintenanceCard name="KB3 Bakımı" date={maintenancesContents[2].date} km={maintenancesContents[2].km} explaining={maintenancesContents[2].explaining} />
                                </div>
                            </div> : <p>Veriler yükleniyor.</p>
                        }
                    </div>

                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary" data-bs-dismiss="modal">
                            Kapat
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CardPopup