import React, { Component } from 'react'
import Footer from '../../Footer/Footer'
import Header from '../../Header/Header'
import Navbar from '../../Navbar/Navbar'
import MaintenancesListBody from './MaintenancesListBody'

export default class MaintenanceList extends Component {
  render() {
    return (
      <>
          <Navbar />
          <Header />
          <MaintenancesListBody />
          <Footer />
      </>
    )
  }
}
