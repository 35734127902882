import * as constants from './constants';

const initialState = {
    isLogin: false,
    userAuthority: "",
    pastMaintenanceFilterList: [],
}

export default function store(state = initialState, action) {
    const actions = action;

    switch (actions.type) {
        case constants.IS_LOGIN: {
            return {
                ...state,
                isLogin: action.payload
            }
        }

        case constants.SET_USER_AUTHORITY: {
            return {
                ...state,
                userAuthority: action.payload
            }
        }

        case constants.SET_FILTER_PAST_MAINTENANCE_LIST: {
            return {
                ...state,
                pastMaintenanceFilterList: action.payload
            }
        }

        case constants.SET_DEFAULT_VALUES: {
            return {
                ...state,
                isLogin: false,
                userAuthority: "",
                pastMaintenanceFilterList: [],
            }
        }

        default:
            return state;
    }
}