import axios from 'axios';
import React, { Component } from 'react'
import { API } from '../../../API/Api';
import Footer from '../../Footer/Footer';
import FormPageButton from '../../FormPagesCommon/FormPageButton';
import FormPageHeader from '../../FormPagesCommon/FormPageHeader';
import Header from '../../Header/Header';
import Navbar from '../../Navbar/Navbar';

class AddTrain extends Component {
    state = {
        "trainName": "",
        "responseMessageClass": "",
        "responseMessage": ""
    }

    onChangeInput = e => {
        this.setState({
            trainName: e.target.value,
            responseMessage: ""
        })
    }

    onClickSave = async (e) => {
        e.preventDefault();
        const { trainName } = this.state;

        await axios.post(API + "/train/add", {
            trainName: trainName
        })
            .then(response => {
                this.setState({
                    responseMessage: response.data.successMessage,
                    responseMessageClass: "alert alert-success",
                    trainName: ""
                })
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data.errorMessage) {
                        this.setState({
                            responseMessage: error.response.data.errorMessage,
                            responseMessageClass: "alert alert-danger"
                        })
                    }
                }
            })

    }

    render() {

        const { trainName, responseMessage, responseMessageClass } = this.state;
        return (
            <div>
                <Navbar />
                <Header />
                <div className="formPage">

                    <form onSubmit={this.onClickSave}>
                        
                        <FormPageHeader header="Yeni Tren Ekle" />
                        
                        <p>
                            <br />
                            Lütfen aşağıda ki bilgileri eksiksiz doldurunuz ardından "Yeni Treni Kaydet" butonuna basınız.
                            Teşekkürler!</p>
                        <hr />

                        <fieldset>
                            <legend >Yeni Tren Ekle</legend>

                            {
                                responseMessage ? <div className={responseMessageClass + " p-2"} role="alert">
                                    {responseMessage}
                                </div> : null
                            }

                            <div className="item">
                                <label htmlFor="trainName">Yeni Tren Adı <span>*(Zorunlu)</span></label>
                                <input id="trainName" type="text" name="trainName" placeholder="Yeni Trenin adını giriniz."
                                    onChange={this.onChangeInput} value={trainName} required />
                            </div>

                        </fieldset>
                        <FormPageButton buttonName="YENİ TRENİ KAYDET" />
                    </form>
                </div>
                <Footer />
            </div>
        )
    }
}
export default AddTrain;