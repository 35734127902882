import axios from 'axios';
import React, { Component } from 'react'
import { API } from '../../../API/Api';
import UserContext from '../../../context/UserContext';
import FormPageButton from '../../FormPagesCommon/FormPageButton';
import FormPageHeader from '../../FormPagesCommon/FormPageHeader';
import FormPageMaintenanceHeader from '../../FormPagesCommon/FormPageMaintenanceHeader';

class MaintenanceUpdateBody extends Component {
    static contextType = UserContext;

    state = {
        "trainName": "",
        "explainingMaintenance":"",
        "service_Maintenance_Explaining": "",
        "service_Maintenance_Km": "",
        "service_Maintenance_Date": "",
        "kb1_Maintenance_Explaining": "",
        "kb1_Maintenance_Km": "",
        "kb1_Maintenance_Date": "",
        "kb2_Maintenance_Explaining": "",
        "kb2_Maintenance_Km": "",
        "kb2_Maintenance_Date": "",
        "kb3_Maintenance_Explaining": "",
        "kb3_Maintenance_Km": "",
        "kb3_Maintenance_Date": "",
        "trains": [],
        "responseMessage": "",
        "responseMessageClass": "",
    }

    componentDidMount = async () => {

        await Promise.all([
            this.context.getUserId(),

            axios.get(API + "/train/list")
                .then(response => {
                    this.setState({
                        trains: response.data
                    })
                })
                .catch(() => {
                    window.location.replace("/login");
                }),

            axios.get(API + `/maintenance/detail/${this.props.maintenanceId}`)
                .then(response => {
                    const { trainName, explainingMaintenance, maintenancesContents } = response.data[0];
                    this.setState({
                        trainName,
                        explainingMaintenance,
                        kb1_Maintenance_Explaining: maintenancesContents[0].explaining,
                        kb1_Maintenance_Date: maintenancesContents[0].date,
                        kb1_Maintenance_Km: maintenancesContents[0].km,
                        kb2_Maintenance_Explaining: maintenancesContents[1].explaining,
                        kb2_Maintenance_Date: maintenancesContents[1].date,
                        kb2_Maintenance_Km: maintenancesContents[1].km,
                        kb3_Maintenance_Explaining: maintenancesContents[2].explaining,
                        kb3_Maintenance_Date: maintenancesContents[2].date,
                        kb3_Maintenance_Km: maintenancesContents[2].km,
                        service_Maintenance_Explaining: maintenancesContents[3].explaining,
                        service_Maintenance_Date: maintenancesContents[3].date,
                        service_Maintenance_Km: maintenancesContents[3].km,
                    })
                })
                .catch(() => {
                    window.location.replace("/login");
                })
        ])

    };

    onChangeTrainName = e => {
        this.setState({
            trainName: e.target.value,
            responseMessage: ""
        })
    };

    onChangeInput = e => {
        this.setState({
            [e.target.name]: e.target.value,
            responseMessage: ""
        })
    };

    onClickUpdate = async (e) => {
        e.preventDefault();
        const { trainName, explainingMaintenance, kb1_Maintenance_Explaining, kb1_Maintenance_Date, kb1_Maintenance_Km,
            kb2_Maintenance_Explaining, kb2_Maintenance_Date, kb2_Maintenance_Km,
            kb3_Maintenance_Explaining, kb3_Maintenance_Date, kb3_Maintenance_Km,
            service_Maintenance_Explaining, service_Maintenance_Date, service_Maintenance_Km } = this.state;

        await axios.put(API + `/maintenance/update/${this.props.maintenanceId}`, {
            trainName,
            userId: this.context.userId,
            explainingMaintenance,
            kb1_Maintenance_Explaining,
            kb1_Maintenance_Date,
            kb1_Maintenance_Km,
            kb2_Maintenance_Explaining,
            kb2_Maintenance_Date,
            kb2_Maintenance_Km,
            kb3_Maintenance_Explaining,
            kb3_Maintenance_Date,
            kb3_Maintenance_Km,
            service_Maintenance_Explaining,
            service_Maintenance_Date,
            service_Maintenance_Km

        })
            .then(response => {
                this.setState({
                    responseMessage: response.data.responseSuccessMessage,
                    responseMessageClass: "alert alert-success"
                })
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.data.responseErrorMessage) {
                        this.setState({
                            responseMessage: error.response.data.responseErrorMessage,
                            responseMessageClass: "alert alert-danger"
                        })
                    }
                }
            })
    }

    render() {
        const { trainName, trains, explainingMaintenance, kb1_Maintenance_Explaining, kb1_Maintenance_Date, kb1_Maintenance_Km,
            kb2_Maintenance_Explaining, kb2_Maintenance_Date, kb2_Maintenance_Km, kb3_Maintenance_Explaining, kb3_Maintenance_Date,
            kb3_Maintenance_Km, service_Maintenance_Explaining, service_Maintenance_Date, service_Maintenance_Km, responseMessage, responseMessageClass } = this.state;

        return (
            <div className="formPage">

                <form onSubmit={this.onClickUpdate}>
                    <FormPageHeader header="Bakımı Güncelle" />
                    <p>
                        <br />
                        Lütfen aşağıda * ile belirtilmiş bilgileri eksiksiz doldurunuz ardından "Bakımı Güncelle" butonuna basınız.
                        Teşekkürler!
                    </p>
                    <hr />

                    <fieldset>
                        <legend >Bakımı Güncelle</legend>

                        {
                            responseMessage ? <div className={responseMessageClass + " p-2"} role="alert">
                                {responseMessage}
                            </div> : null
                        }

                        <div className="item">
                            <label htmlFor="trainName">Tren <span>*(Zorunlu)</span></label>
                            <select className='py-2' id="trainName" name="trainName"
                                onChange={this.onChangeTrainName} value={trainName}>
                                <option value="">Seçiniz</option>
                                {
                                    trains.map(train => (
                                        <option value={train._id} key={train._id}>{train.trainName}</option>
                                    ))
                                }
                            </select>
                        </div>

                        <div className="item">
                            <label htmlFor="explainingMaintenance">Açıklama <span></span></label>
                            <input id="explainingMaintenance" type="text" name="explainingMaintenance" placeholder="Genel açıklama giriniz."
                                onChange={this.onChangeInput} value={explainingMaintenance} />
                        </div>

                        <FormPageMaintenanceHeader maintenanceHeader="Servis" />

                        <div className="item">
                            <label htmlFor="service_Maintenance_Km">Servis Bakım Km <span>*(Zorunlu)</span></label>
                            <input id="service_Maintenance_Km" type="number" name="service_Maintenance_Km" placeholder="Servis Bakımı için Km giriniz."
                                onChange={this.onChangeInput} value={service_Maintenance_Km} required />
                        </div>

                        <div className="item">
                            <label htmlFor="service_Maintenance_Explaining">Servis Bakımı Açıklama <span>*(Zorunlu)</span></label>
                            <textarea id="service_Maintenance_Explaining" name="service_Maintenance_Explaining" placeholder="Servis Bakım için Açıklama giriniz."
                                onChange={this.onChangeInput} value={service_Maintenance_Explaining} rows="3" required />
                        </div>

                        <div className="item">
                            <label htmlFor="service_Maintenance_Date">Servis Son Bakım Tarihi <span>*(Zorunlu)</span></label>
                            <div className="name-item">
                                <input id="service_Maintenance_Date" type="date" name="service_Maintenance_Date"
                                    onChange={this.onChangeInput} value={service_Maintenance_Date} required />
                            </div>
                        </div>

                        <FormPageMaintenanceHeader maintenanceHeader="KB1" />

                        <div className="item">
                            <label htmlFor="kb1_Maintenance_Km">KB1 Bakım Km  </label>
                            <input id="kb1_Maintenance_Km" type="number" name="kb1_Maintenance_Km" placeholder="KB1 Bakımı için Km giriniz."
                                onChange={this.onChangeInput} value={kb1_Maintenance_Km}  />
                        </div>

                        <div className="item">
                            <label htmlFor="kb1_Maintenance_Explaining">KB1 Bakımı Açıklama  </label>
                            <textarea id="kb1_Maintenance_Explaining" name="kb1_Maintenance_Explaining" placeholder="KB1 Bakım için Açıklama giriniz."
                                onChange={this.onChangeInput} value={kb1_Maintenance_Explaining} rows="3"  />
                        </div>

                        <div className="item">
                            <label htmlFor="kb1_Maintenance_Date">KB1 Son Bakım Tarihi  </label>
                            <div className="name-item">
                                <input id="kb1_Maintenance_Date" type="date" name="kb1_Maintenance_Date"
                                    onChange={this.onChangeInput} value={kb1_Maintenance_Date}  />

                            </div>
                        </div>

                        <FormPageMaintenanceHeader maintenanceHeader="KB2" />

                        <div className="item">
                            <label htmlFor="kb2_Maintenance_Km">KB2 Bakımı Km  </label>
                            <input id="kb2_Maintenance_Km" type="number" name="kb2_Maintenance_Km" placeholder="KB2 Bakımı için Km giriniz."
                                onChange={this.onChangeInput} value={kb2_Maintenance_Km}  />
                        </div>

                        <div className="item">
                            <label htmlFor="kb2_Maintenance_Explaining">KB2 Bakımı Açıklama  </label>
                            <textarea id="kb2_Maintenance_Explaining" name="kb2_Maintenance_Explaining" placeholder="KB2 Bakım için Açıklama giriniz"
                                onChange={this.onChangeInput} value={kb2_Maintenance_Explaining} rows="3"  />
                        </div>

                        <div className="item">
                            <label htmlFor="kb2_Maintenance_Date">KB2 Son Bakım Tarihi  </label>
                            <div className="name-item">
                                <input id="kb2_Maintenance_Date" type="date" name="kb2_Maintenance_Date"
                                    onChange={this.onChangeInput} value={kb2_Maintenance_Date}  />
                            </div>
                        </div>

                        <FormPageMaintenanceHeader maintenanceHeader="KB3" />

                        <div className="item">
                            <label htmlFor="kb3_Maintenance_Km">KB3 Bakımı Km </label>
                            <input id="kb3_Maintenance_Km" type="number" name="kb3_Maintenance_Km" placeholder="KB3 Bakımı için Km giriniz."
                                onChange={this.onChangeInput} value={kb3_Maintenance_Km}  />
                        </div>

                        <div className="item">
                            <label htmlFor="kb3_Maintenance_Explaining">KB3 Bakımı Açıklama  </label>
                            <textarea id="kb3_Maintenance_Explaining" name="kb3_Maintenance_Explaining" placeholder="KB3 Bakım için Açıklama giriniz"
                                onChange={this.onChangeInput} value={kb3_Maintenance_Explaining} rows="3"  />
                        </div>

                        <div className="item">
                            <label htmlFor="kb3_Maintenance_Date">KB3 Son Bakım Tarihi  </label>
                            <div className="name-item">
                                <input id="kb3_Maintenance_Date" type="date" name="kb3_Maintenance_Date"
                                    onChange={this.onChangeInput} value={kb3_Maintenance_Date}  />
                            </div>
                        </div>

                    </fieldset>
                    <FormPageButton buttonName="BAKIMI GÜNCELLE" />
                </form>
            </div>
        )
    }
}
export default MaintenanceUpdateBody;