import axios from 'axios'
import React, { Component } from 'react'
import { API } from '../../../API/Api'
import PastMaintenancesDetailBody from '../PastMaintenanceDetail/PastMaintenancesDetailBody';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectUserAuthority } from '../../../Store/selectors';
import ListBody from '../../ListPart/ListBody';
import ListHeader from '../../ListPart/ListHeader';

class PastMaintenancesListBody extends Component {

    state = {
        "pastMaintenances": [],
        "successMessage": "",
    }

    componentDidMount = async () => {
        await axios.get(API + "/past-maintenances/list")
            .then(response => this.setState({
                pastMaintenances: response.data
            }))
            .catch(() => window.location.replace("/login"))
    }

    onClickDelete = async (pastMaintenanceId) => {
        await axios.delete(API + `/past-maintenances/delete/${pastMaintenanceId}`)
            .then(response => {
                this.setState({
                    pastMaintenances: this.state.pastMaintenances.filter(pastMaintenance => pastMaintenance._id !== pastMaintenanceId),
                    successMessage: response.data.successMessage
                })
            })
    };

    onDateFormat = date => {
        return date.split("-").reverse().join("-")
    };

    render() {

        const data = {
            columns: [
                {
                    label: 'Trenin Adı',
                    field: 'trainName',
                    sort: 'asc',
                },
                {
                    label: 'Service Bakım Tarihi',
                    field: 'service_Maintenance_Date',
                    sort: 'asc',
                },
                {
                    label: 'Service Bakım Açıklama',
                    field: 'service_Maintenance_Explaining',
                    sort: 'asc',
                },
                {
                    label: 'KB1 Bakım Tarihi',
                    field: 'kb1_Maintenance_Date',
                    sort: 'asc',
                },
                {
                    label: 'KB1 Bakım Açıklama',
                    field: 'kb1_Maintenance_Explaining',
                    sort: 'asc',
                },
                {
                    label: 'KB2 Bakım Tarihi',
                    field: 'kb2_Maintenance_Date',
                    sort: 'asc',
                },
                {
                    label: 'KB2 Bakım Açıklama',
                    field: 'kb2_Maintenance_Explaining',
                    sort: 'asc',
                },
                {
                    label: 'KB3 Bakım Tarihi',
                    field: 'kb3_Maintenance_Date',
                    sort: 'asc',
                },
                {
                    label: 'KB3 Bakım Açıklama',
                    field: 'kb3_Maintenance_Explaining',
                    sort: 'asc',
                },
                {
                    label: 'İşlemler',
                    field: 'operations',
                    sort: 'asc',
                },
            ],
            rows: this.state.pastMaintenances.map(pastMaintenance => {
                const { onDateFormat } = this;
                const { trainInformation, maintenancesContents } = pastMaintenance;
                return {
                    trainName: trainInformation[0].trainName,
                    kb1_Maintenance_Date: onDateFormat(maintenancesContents[0].date),
                    kb1_Maintenance_Explaining: maintenancesContents[0].explaining,
                    kb2_Maintenance_Date: onDateFormat(maintenancesContents[1].date),
                    kb2_Maintenance_Explaining: maintenancesContents[1].explaining,
                    kb3_Maintenance_Date: onDateFormat(maintenancesContents[2].date),
                    kb3_Maintenance_Explaining: maintenancesContents[2].explaining,
                    service_Maintenance_Date: onDateFormat(maintenancesContents[3].date),
                    service_Maintenance_Explaining: maintenancesContents[3].explaining,
                    operations: <>
                        {
                            this.props.userAuthority === "Manager" ? <button className='btn delete-button me-2 mb-1' onClick={this.onClickDelete.bind(this, pastMaintenance._id)}>
                                Bakımı Sil
                            </button> : null
                        }
                        <button className='btn update-button' data-bs-toggle="modal" data-bs-target={"#o" + pastMaintenance._id}>
                            Detay
                        </button>
                        <PastMaintenancesDetailBody maintenance={pastMaintenance} />
                    </>
                }
            })
        };

        return (
            <>
                <div className='datatable-page'>
                    <div className='past-maintenance-list'>
                        <ListHeader header="Geçmiş Bakım Listesi" />
                        {this.state.successMessage ? <div className="alert alert-success response-message">{this.state.successMessage}</div> : null}
                        <ListBody data={data} />
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = createStructuredSelector({
    userAuthority: selectUserAuthority()
});

export default connect(mapStateToProps)(PastMaintenancesListBody);