import React, { Component } from 'react';
import Footer from '../../Footer/Footer';
import Header from '../../Header/Header';
import Navbar from '../../Navbar/Navbar';
import AddUserBody from './AddUserBody';

class AddUser extends Component {
  render() {
    return (
      <>
          <Navbar />
          <Header />
          <AddUserBody />
          <Footer />
      </>
    )
  }
}

export default AddUser;