import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { API } from '../../API/Api'
import { setUserAuthority } from '../../Store/actions';
import Navbar from '../Navbar/Navbar';
import Header from '../Header/Header'
import Card from './Card/Card';
import CardPopup from './CardPopup/CardPopup';
import Footer from '../Footer/Footer';

function HomePage(props) {
  const [maintenanceList, setMaintenanceList] = useState([]);

  useEffect(() => {
    const getUser = async () => {
      await axios.get(API + "/authentication/loggedIn").then(async(response)=>{
        await axios.get(API + `/user/detail/${response.data}`)
        .then(async (res) => {
          await props.setUserAuthority(res.data.authority)
        })
      })    
    };
    
    getUser();

    getMaintenances();

  }, [props]);

  const getMaintenances = async () => {
    await axios.get(API + "/maintenance/list")
      .then(response => setMaintenanceList(response.data))
      .catch(() => window.location.replace("/login"));
  }


  return (
    <div>
      <Navbar />
      <Header />
      <div className="homePage">
        {maintenanceList.map(maintenance => (
          <div key={maintenance._id}>
            <Card maintenance={maintenance} />
            <CardPopup maintenance={maintenance} />
          </div>
        ))}
      </div>
      <Footer />
    </div>
  )
}

const mapDispatchToProps = dispatch => (
  (
    bindActionCreators({ setUserAuthority }, dispatch)
  )
)
export default connect(null, mapDispatchToProps)(HomePage);