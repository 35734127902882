import React, { Component } from 'react'
import axios from 'axios';
import { API } from '../../API/Api';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setPastMaintenancesFilterList } from '../../Store/actions';

class FilterBody extends Component {

    state = {
        "trains": [],
        "trainName": "",
        "dateStart": "",
        "dateEnd": "",
        "pastMaintenances": "",
        "maintenanceType": "",
    }

    componentDidMount = async () => {

        await Promise.all([
            axios.get(API + "/train/list")
                .then(response => {
                    this.setState({
                        trains: response.data
                    })
                }),
            axios.get(API + "/past-maintenances/list")
                .then(response => {
                    this.setState({
                        pastMaintenances: response.data
                    })
                    this.props.setPastMaintenancesFilterList(response.data)
                })
        ])
    }

    onChangeSelectPart = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onChangeDate = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onClearFilter = () => {
        this.setState({
            dateEnd: "",
            dateStart: "",
            trainName: "",
            maintenanceType: ""
        })
    }

    onClickFilter = async (e) => {
        e.preventDefault();
        const { trainName, dateStart, dateEnd, maintenanceType, pastMaintenances } = this.state;
        let filterMaintenanceList = [];
        let index;

        const IndexOfMaintenance = () => {
            pastMaintenances.forEach(pastMaintenance => {
                if (!index) {
                    index = pastMaintenance.maintenancesContents.map(maintenance => {
                        return maintenance.name
                    }).indexOf(maintenanceType)
                }
                else return index;
            })
        }

        if ((dateStart !== "" && dateEnd === "") || (dateStart === "" && dateEnd !== "")) {
            alert("Lütfen başlangıç ve bitiş tarihlerinin ikisini de seçiniz.")
        }

        else {
            if ((trainName && !maintenanceType && !dateStart && !dateEnd) || (trainName && maintenanceType && !dateStart && !dateEnd)) {
                filterMaintenanceList = pastMaintenances.filter(pastMaintenance => pastMaintenance.trainName === trainName)
                await this.props.setPastMaintenancesFilterList(filterMaintenanceList);
            }

            else if ((!trainName && !maintenanceType && !dateStart && !dateEnd) || (!trainName && maintenanceType && !dateStart && !dateEnd)) {
                await this.props.setPastMaintenancesFilterList(pastMaintenances)
            }

            else if (trainName && maintenanceType && dateStart && dateEnd) {

                IndexOfMaintenance();

                filterMaintenanceList = pastMaintenances.filter(pastMaintenance => pastMaintenance.trainName === trainName && new Date(dateStart).getTime() <= new Date(pastMaintenance.maintenancesContents[index].date).getTime()
                    && new Date(pastMaintenance.maintenancesContents[index].date).getTime() <= new Date(dateEnd).getTime())


                await this.props.setPastMaintenancesFilterList(filterMaintenanceList);
            }

            else if (!trainName && maintenanceType && dateStart && dateEnd) {

                IndexOfMaintenance();

                filterMaintenanceList = pastMaintenances.filter(pastMaintenance => new Date(dateStart).getTime() <= new Date(pastMaintenance.maintenancesContents[index].date).getTime()
                    && new Date(pastMaintenance.maintenancesContents[index].date).getTime() <= new Date(dateEnd).getTime())

                await this.props.setPastMaintenancesFilterList(filterMaintenanceList);
            }

            else if (!maintenanceType && dateStart && dateEnd) {
                pastMaintenances.forEach(pastMaintenance => {
                    pastMaintenance.maintenancesContents.forEach(maintenance => {
                        if (new Date(dateStart) <= new Date(maintenance.date) && new Date(maintenance.date) <= new Date(dateEnd)) {
                            filterMaintenanceList.push(pastMaintenance)
                        }
                    })
                })

                filterMaintenanceList = filterMaintenanceList.filter((maintenance, index) => {
                    return filterMaintenanceList.indexOf(maintenance) === index
                })

                if (!trainName)
                    await this.props.setPastMaintenancesFilterList(filterMaintenanceList);

                else {
                    filterMaintenanceList = filterMaintenanceList.filter(filterPastMaintenance => filterPastMaintenance.trainName === trainName)
                    await this.props.setPastMaintenancesFilterList(filterMaintenanceList);
                }
            }

            else {
                await this.props.setPastMaintenancesFilterList(filterMaintenanceList)
            }
        }
    }

    render() {

        const { trains, trainName, dateStart, dateEnd, maintenanceType } = this.state;
        return (
            <div className='p-3 filter'>
                <div className="form-group row">
                    <label htmlFor="trainName" className="col-sm-3 col-form-label">
                        Tren Numarası:
                    </label>
                    <div className="col-sm-8" >
                        <select className="col-sm-12 form-select p-2" id="trainName" name="trainName"
                            value={trainName} onChange={this.onChangeSelectPart} >
                            <option value="">Tüm trenler</option>
                            {
                                trains.map(train => (
                                    <option key={train._id} value={train._id}>{train.trainName}</option>
                                ))
                            }

                        </select>
                    </div>
                </div>

                <div className="form-group row">
                    <label htmlFor="maintenanceType" className="col-sm-3 col-form-label">
                        Bakım Türü:
                    </label>
                    <div className="col-sm-8" >
                        <select className="col-sm-12 form-select p-2" id="maintenanceType" name="maintenanceType"
                            value={maintenanceType} onChange={this.onChangeSelectPart} >
                            <option value="">Tüm bakımlar</option>
                            <option key="1" value="service">Servis Bakımı</option>
                            <option key="2" value="kb1">KB1 Bakımı</option>
                            <option key="3" value="kb2">KB2 Bakımı</option>
                            <option key="4" value="kb3">KB3 Bakımı</option>
                        </select>
                    </div>
                </div>

                <div className="form-group row">
                    <label htmlFor="dateStart" className="col-sm-3 col-form-label">
                        Başlangıç Tarihi:
                    </label>
                    <div className="col-sm-8" >
                        <input type="date" max={dateEnd} className="col-sm-12 p-2" rows="6" name="dateStart" value={dateStart} onChange={this.onChangeDate} required />
                    </div>
                </div>

                <div className="form-group row item">
                    <label htmlFor="dateEnd" className="col-sm-3 col-form-label">
                        Bitiş Tarihi:
                    </label>
                    <div className="col-sm-8 ame-item" >
                        <input type="date" min={dateStart} className="col-sm-12 p-2" rows="6" name="dateEnd" value={dateEnd} onChange={this.onChangeDate} required />
                    </div>
                </div>

                <div className=" row text-right mt-4">
                    <div className="col-sm-11">
                        <button className="btn right-part-clear-date mr-2 " onClick={this.onClearFilter}>
                            Filtreleri Temizle
                        </button>
                        <button className="btn right-part-button" onClick={this.onClickFilter}>
                            Filtrele
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => (
    (
        bindActionCreators({ setPastMaintenancesFilterList }, dispatch)
    )
)

export default connect(null, mapDispatchToProps)(FilterBody);