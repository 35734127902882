import React from 'react'

function Footer() {
    return (
        <div className="footer">
            <p>
                Copyright © 2022 TMS Technic, Tüm Hakları Saklıdır. Made by TMS AR-GE.
            </p>
        </div>
    )
}
export default Footer;