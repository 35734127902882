import React from 'react';
import Navbar from '../../Navbar/Navbar';
import Header from '../../Header/Header';
import MaintenanceAddBody from './MaintenanceAddBody';
import Footer from '../../Footer/Footer';

function MaintenanceAdd() {
  return (
    <>
        <Navbar />
        <Header />
        <MaintenanceAddBody />
        <Footer />
    </>
  )
}
export default MaintenanceAdd;