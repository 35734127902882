import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { API } from '../../../API/Api';
import FormPageButton from '../../FormPagesCommon/FormPageButton';
import FormPageHeader from '../../FormPagesCommon/FormPageHeader';

function TrainUpdateBody() {
    let { id } = useParams();

    const [trainName, setTrainName] = useState("");
    const [responseMessage, setResponseMessage] = useState("");
    const [responseMessageClass, setResponseMessageClass] = useState("");

    useEffect(()=>{
        getTrain();
    },[]);

    const getTrain = async() => {
        await axios.get(`${API}/train/detail/${id}`)
        .then(response => {
            setTrainName(response.data.trainName);
            setResponseMessage(response.data.successMessage);
            setResponseMessageClass("alert alert-success");
        })
        .catch(error => {
            if(error.response){
                if(error.response.data.errorMessage){
                    setResponseMessage(error.response.data.errorMessage);
                    setResponseMessageClass("alert alert-danger");
                }
            }
        })
    }

    const onChangeInput = e => {
        setTrainName(e.target.value);
        setResponseMessage("");
        setResponseMessageClass("");
    }

    const onClickSave = async(e) => {
        e.preventDefault();
        await axios.put(API + `/train/update/${id}`,{
            trainName
        })
        .then(response => {
            setResponseMessage(response.data.successMessage);
            setResponseMessageClass("alert alert-success")
        })
        .catch(error => {
            if(error.response){
                if(error.response.data.errorMessage){
                    setResponseMessage(error.response.data.errorMessage);
                    setResponseMessageClass("alert alert-danger")
                }
            }
        })
    }

    return (
        <div className="formPage">

            <form onSubmit={onClickSave}>
                <FormPageHeader header="Tren Bilgisini Güncelle" />
                <p>
                    <br />
                    Lütfen aşağıda ki bilgileri eksiksiz doldurunuz ardından "Yeni Treni Kaydet" butonuna basınız.
                    Teşekkürler!</p>
                <hr />

                <fieldset>
                    <legend >Tren Güncelle</legend>

                    {
                        responseMessage ? <div className={responseMessageClass + " p-2"} role="alert">
                            {responseMessage}
                        </div> : null
                    }

                    <div className="item">
                        <label htmlFor="trainName">Yeni Tren Adı <span>*(Zorunlu)</span></label>
                        <input id="trainName" type="text" name="trainName" placeholder="Yeni Trenin adını giriniz."
                            onChange={onChangeInput} value={trainName} required />
                    </div>

                </fieldset>

                <FormPageButton buttonName="TRENİN BİLGİLERİNİ GÜNCELLE" />
            </form>
        </div>
    )
}
export default TrainUpdateBody;