import React, { Component } from 'react';
import Navbar from '../Navbar/Navbar';
import Header from '../Header/Header';
import FilterBody from './FilterBody';
import AfterFilterList from './AfterFilterList';

class Filter extends Component {
    render() {
        return (
            <>
                <Navbar />
                <Header />
                <div className='datatable-page'>
                    <div className='user-list'>
                        <div className='part-header'>
                            Geçmiş Bakımları Filtreleme
                        </div>
                        <div className='part-body'>
                            <FilterBody />
                            <AfterFilterList />
                        </div>
                    </div>
                </div>

            </>
        )
    }
}
export default Filter;