import React, { useEffect } from 'react'

function InNotFound() {

  useEffect(() => {
    window.location.replace("/");
  },[]);

  return (
    <div></div>
  )
}
export default InNotFound;