import axios from 'axios';
import React, { Component } from 'react';
import { API } from '../../../API/Api';
import { Link } from 'react-router-dom';
import ListBody from '../../ListPart/ListBody';
import ListHeader from '../../ListPart/ListHeader';

class MaintenancesListBody extends Component {

    state = {
        "maintenances": [],
        "successMessage": "",
    }

    componentDidMount = async () => {
        await axios.get(API + "/maintenance/list")
            .then(response => {
                this.setState({
                    maintenances: response.data.sort((first, second) => {
                        if (first.trainInformation[0].trainName.toLowerCase() < second.trainInformation[0].trainName.toLowerCase()) {
                            return -1;
                        }
                        return 0;
                    })
                })
            }).catch(() => {
                 window.location.replace("/login");
            });
    }

    onClickDelete = async(maintenanceId) => {
        await axios.delete(API + `/maintenance/delete/${maintenanceId}`)
        .then(response => {
            this.setState({
                maintenances: this.state.maintenances.filter( maintenance => maintenance._id !== maintenanceId),
                successMessage:response.data.responseSuccessMessage
            })
        })
    }

    onDateFormat = date => {
        return date.split("-").reverse().join("-")
    }


    render() {
        const data = {
            columns: [
                {
                    label: 'Trenin Adı',
                    field: 'trainName',
                    sort: 'asc',
                },
                {
                    label: 'Servis Bakım Tarihi',
                    field: 'service_Maintenance_Date',
                    sort: 'asc',
                },
                {
                    label: 'Servis Bakım Açıklama',
                    field: 'service_Maintenance_Explaining',
                    sort: 'asc',
                },
                {
                    label: 'KB1 Bakım Tarihi',
                    field: 'kb1_Maintenance_Date',
                    sort: 'asc',
                },
                {
                    label: 'KB1 Bakım Açıklama',
                    field: 'kb1_Maintenance_Explaining',
                    sort: 'asc',
                },
                {
                    label: 'KB2 Bakım Tarihi',
                    field: 'kb2_Maintenance_Date',
                    sort: 'asc',
                },
                {
                    label: 'KB2 Bakım Açıklama',
                    field: 'kb2_Maintenance_Explaining',
                    sort: 'asc',
                },
                {
                    label: 'KB3 Bakım Tarihi',
                    field: 'kb3_Maintenance_Date',
                    sort: 'asc',
                },
                {
                    label: 'KB3 Bakım Açıklama',
                    field: 'kb3_Maintenance_Explaining',
                    sort: 'asc',
                },
                {
                    label: 'İşlemler',
                    field: 'operations',
                    sort: 'asc',
                },
            ],
            rows: this.state.maintenances.map(maintenance => {
                const { onDateFormat } = this;
                const { trainInformation, maintenancesContents} = maintenance;
                return {
                    trainName: trainInformation[0].trainName,
                    kb1_Maintenance_Date: onDateFormat(maintenancesContents[0].date),
                    kb1_Maintenance_Explaining: maintenancesContents[0].explaining,
                    kb2_Maintenance_Date: onDateFormat(maintenancesContents[1].date),
                    kb2_Maintenance_Explaining: maintenancesContents[1].explaining,
                    kb3_Maintenance_Date: onDateFormat(maintenancesContents[2].date),
                    kb3_Maintenance_Explaining: maintenancesContents[2].explaining,
                    service_Maintenance_Date: onDateFormat(maintenancesContents[3].date),
                    service_Maintenance_Explaining: maintenancesContents[3].explaining,
                    operations: <>
                        <button className='btn delete-button me-2 mb-1' onClick={this.onClickDelete.bind(this, maintenance._id)}>
                            Bakımı Sil
                        </button>

                        <Link to={`/maintenance/update/${maintenance._id}`} className='btn update-button'>
                            Bakımı Güncelle
                        </Link>
                    </>
                }
            })
        };

        return (
            <div className='datatable-page'>
                <Link to="/maintenance/add" className='btn add-button'>
                    Yeni Bakım Ekle
                </Link>
                <div className='maintenance-list'>
                    <ListHeader header="Bakım Listesi" />
                    {this.state.responseSuccessMessage ? <div className="alert alert-success response-message">{this.state.responseSuccessMessage}</div> : null}
                    <ListBody data={data} />
                </div>
            </div>
        )
    }
}
export default MaintenancesListBody;