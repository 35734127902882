import axios from 'axios';
import React, { createContext, useEffect, useState } from 'react';
import * as api from '../API/Api';

const UserContext = createContext();

function UserContextProvider(props) {
    const [userId, setUserId] = useState(null);

    const getUserId = async () =>{
        const userRes = await axios.get(api.API + "/authentication/loggedIn");
        setUserId(userRes.data);
    };

    useEffect(() => {
        getUserId();  
    }, []);

    return (
        <UserContext.Provider value={{userId, getUserId}}>
            {props.children}
        </UserContext.Provider>
    )
}
export default UserContext;
export { UserContextProvider };