import axios from 'axios';
import React, { Component } from 'react';
import { API } from '../../../API/Api';
import FormPageButton from '../../FormPagesCommon/FormPageButton';
import FormPageHeader from '../../FormPagesCommon/FormPageHeader';

class AddUserBody extends Component {

    state = {
        "name": "",
        "surname": "",
        "userName": "",
        "password": "",
        "passwordVerify": "",
        "authority": "Manager",
        "email": "",
        "responseMessageClass": "",
        "responseMessage": ""
    }

    onChangeInput = e => {
        this.setState({
            [e.target.name]: e.target.value,
            responseMessage: ""
        });
    };

    onChangeAuthority = e => {
        this.setState({
            authority: e.target.value,
            responseMessage: ""
        })
    };

    onClickSave = async (e) => {
        e.preventDefault();
        const { name, surname, password, passwordVerify, authority, userName, email } = this.state;

        await axios.post(API + "/user/add", {
            name,
            surname,
            password,
            passwordVerify,
            userName,
            email,
            authority
        }).then((response) => {
            this.setState({
                name: "",
                surname: "",
                userName: "",
                password: "",
                passwordVerify: "",
                authority: "Manager",
                email: "",
                responseMessageClass: "alert alert-success",
                responseMessage: response.data.successMessage
            });
        })
            .catch((err) => {
                if (err.response) {
                    if (err.response.data.errorMessage) {
                        this.setState({
                            responseMessage: err.response.data.errorMessage,
                            responseMessageClass: "alert alert-danger"
                        })
                    }
                }
            })
    }

    render() {
        const { name, surname, password, passwordVerify, authority, userName, email, responseMessage, responseMessageClass } = this.state;
        return (
            <div className="formPage">

                <form onSubmit={this.onClickSave}>
                    <FormPageHeader header="Kullanıcı Ekle" />
                    <p>
                        <br />
                        Lütfen aşağıdaki * ile belirtilmiş bilgileri eksiksiz doldurunuz. Ardından "Kullanıcı Ekle" butonuna basınız.
                        Teşekkürler!</p>
                    <hr />

                    <fieldset>
                        <legend >Kullanıcı Ekle</legend>

                        {
                            responseMessage ? <div className={responseMessageClass + " p-2"} role="alert">
                                {responseMessage}
                            </div> : null
                        }

                        <div className="item">
                            <label htmlFor="name">Adınız ve Soyadınız <span>*(Zorunlu)</span></label>
                            <div className="name-item">
                                <input id="name" type="text" name="name" placeholder="Adınız"
                                    onChange={this.onChangeInput} value={name} required />
                                <input id="name" type="text" name="surname" placeholder="Soyadınız"
                                    onChange={this.onChangeInput} value={surname} required />
                            </div>
                        </div>

                        <div className="item">
                            <label htmlFor="userName">Kullanıcı Adınız <span>*(Zorunlu)</span></label>
                            <input id="userName" type="text" name="userName" placeholder="Kullanıcı adı giriniz"
                                onChange={this.onChangeInput} value={userName} required />
                        </div>

                        <div className="item">
                            <label htmlFor="password">Şifreniz <span>*(Zorunlu)</span></label>
                            <input id="password" type="password" name="password" placeholder="Şifrenizi giriniz. Lütfen en az 6 ve en fazla 15 karakter giriniz"
                                onChange={this.onChangeInput} value={password} required />
                        </div>

                        <div className="item">
                            <label htmlFor="passwordVerify">Şifre Tekrar <span>*(Zorunlu)</span></label>
                            <input id="passwordVerify" type="password" name="passwordVerify" placeholder="Şifre tekrarını giriniz."
                                onChange={this.onChangeInput} value={passwordVerify} required />
                        </div>

                        <div className="item">
                            <label htmlFor="email">Email <span>*(Zorunlu)</span></label>
                            <input id="email" type="email" name="email" placeholder='Email adresini giriniz'
                                onChange={this.onChangeInput} value={email} required />
                        </div>

                        <div className="item">
                            <label htmlFor="authority">Yetki <span>*(Zorunlu)</span></label>
                            <select className='py-2' id="authority" name="authority"
                                onChange={this.onChangeAuthority} value={authority}>
                                <option value="Manager">Yönetici</option>
                                <option value="User">Kullanıcı</option>
                            </select>
                        </div>

                    </fieldset>

                    <FormPageButton buttonName="KULLANICI EKLE" />
                </form>
            </div>
        )
    }
}
export default AddUserBody;