import React from 'react'
import MaintenanceUpdateBody from './MaintenanceUpdateBody';
import Navbar from '../../Navbar/Navbar';
import Header from '../../Header/Header';
import { useParams } from 'react-router-dom';
import Footer from '../../Footer/Footer';

function MaintenanceUpdate() {

  let {id} = useParams();

  return (
    <>
        <Navbar />
        <Header />
        <MaintenanceUpdateBody maintenanceId = {id} />
        <Footer />
    </>
  )
}
export default MaintenanceUpdate;