import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PasswordRemember from './PasswordRemember/PasswordRemember';
import SignIn from './SignIn/SignIn';
import { setIsLogin, setDefaultValues } from '../../Store/actions';
import axios from 'axios';
import { API } from '../../API/Api';

function Login(props) {

    useEffect(() => {
        const getStart = async () => {
            await axios.get(API + '/authentication/logOut')
                .then(async () => {
                    await props.setIsLogin(false)
                });
        }
        getStart();
        
        props.setDefaultValues();

    }, [props]);

    return (
        <div className='login-page'>
            <div className="main">
                <input type="checkbox" id="chk" aria-hidden="true" />
                <SignIn />
                <PasswordRemember />
            </div>
        </div>
    )
}

const mapDispatchToProps = dispatch => (
    (
        bindActionCreators({ setIsLogin, setDefaultValues }, dispatch)
    )
)
export default connect(null, mapDispatchToProps)(Login);