import React, { Component } from 'react'
import { API } from '../../../API/Api';
import { Link } from 'react-router-dom';
import axios from 'axios';
import ListHeader from '../../ListPart/ListHeader';
import ListBody from '../../ListPart/ListBody';

class TrainListBody extends Component {

    state = {
        "trains": [],
        "successMessage": ""
    }

    componentDidMount = async () => {
        await axios.get(API + "/train/list")

            .then(response => {
                this.setState({
                    trains: response.data
                })
            }).catch(() => {
                window.location.replace("/login");
            });
    }

    onClickDelete = async (id) => {

        await axios.delete(`${API}/train/delete/${id}`)
            .then(response => {
                this.setState({
                    ...this.state,
                    trains: this.state.trains.filter(train => train._id !== id),
                    successMessage: response.data.successMessage
                })
            })
    }

    render() {

        const data = {
            columns: [
                {
                    label: 'Tren Adı',
                    field: 'trainName',
                    sort: 'asc',
                },
                {
                    label: 'İşlemler',
                    field: 'operation',
                    sort: 'asc',
                }
            ],
            rows: this.state.trains.map(train => {
                return {
                    trainName: train.trainName,
                    operation: <>

                        <button className='btn delete-button me-2' onClick={this.onClickDelete.bind(this, train._id)}>
                            Treni Sil
                        </button>

                        <Link to={"/train/update/" + train._id} className='btn update-button'>
                            Treni Güncelle
                        </Link>

                    </>
                }
            })
        }
        return (
            <div className='datatable-page'>
                <Link to="/train/add" className='btn add-button'>
                    Yeni Tren Ekle
                </Link>
                <div className='train-list'>
                    <ListHeader header="Tren Listesi" />
                    {this.state.successMessage ? <div className="alert alert-success response-message">{this.state.successMessage}</div> : null}
                    <ListBody data={data} />
                </div>
            </div>
        )
    }
}
export default TrainListBody;