import React from 'react'
import UserUpdateBody from './UserUpdateBody';
import Navbar from '../../Navbar/Navbar';
import Header from '../../Header/Header';
import { useParams } from 'react-router-dom';
import Footer from '../../Footer/Footer';

function UserUpdate() {
  let { id } = useParams();
    
  return (
    <>
      <Navbar />
      <Header />
      <UserUpdateBody userId={id} />
      <Footer />
    </>
  )
}
export default UserUpdate;