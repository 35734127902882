import React, { Component } from 'react'
import ISG from '../../Docs/ISG.pdf';
import ListBody from '../ListPart/ListBody';
import ListHeader from '../ListPart/ListHeader';

class DocumentsBody extends Component {
  render() {
    const data = {
      columns: [
        {
          label: 'Sıra',
          field: 'documentId',
          sort: 'asc',
        },
        {
          label: 'Döküman Adı',
          field: 'documentName',
          sort: 'asc',
        },
        {
          label: 'Dosyayı İndir',
          field: 'downloadDocument',
          sort: 'asc',
        },

      ],
      rows: [
        {
          documentId: 1,
        documentName: "ISG",
        downloadDocument: <a href={ISG} className="btn update-button" download="ISG">İndir</a>
      },
    ]
    }
    return (
      <div className='datatable-page'>
        <div className='documents-list'>
          <ListHeader header="Dökümanlar" />
          <ListBody data={data} />
        </div>
      </div>
    )
  }
}
export default DocumentsBody;