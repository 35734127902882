import React, { Component } from 'react';

class Card extends Component {
  
  render() {
    const {_id, trainInformation} = this.props.maintenance;

    return (
      <div className="card" data-bs-toggle="modal" data-bs-target={"#o" + _id} >
        <div className='card-body'>
          <p>
            {trainInformation[0].trainName}
          </p>
        </div>
      </div>
    )
  }
}
export default Card;