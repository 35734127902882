import React, { Component } from 'react';
import axios from 'axios';
import UserContext from '../../context/UserContext';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectUserAuthority } from '../../Store/selectors';
import { bindActionCreators } from 'redux';
import { setUserAuthority } from '../../Store/actions';
import { API } from '../../API/Api';
import FormPageButton from '../FormPagesCommon/FormPageButton';

class MyInformation extends Component {

    static contextType = UserContext;

    state = {
        "name": "",
        "surname": "",
        "userName": "",
        "authority": "",
        "email": "",
        "responseMessageClass": "",
        "responseMessage": ""
    }

    componentDidMount = async () => {
        
        // await this.context.getUserId()
        await axios.get(API + "/authentication/loggedIn")
        .then(async(response) => {
            await axios.get(API + `/user/detail/${response.data}`)
            .then(response => {
                this.setState({
                    name: response.data.name,
                    surname: response.data.surname,
                    authority: response.data.authority,
                    userName: response.data.userName,
                    email: response.data.email
                })
            })
            .catch(err => console.log(this.context.userId))
        })
        
       

            
    };

   

    onChangeInput = e => {
        this.setState({
            [e.target.name]: e.target.value,
            responseMessage: ""
        });
    };

    onChangeAuthority = e => {
        this.setState({
            authority: e.target.value,
            responseMessage: ""
        })
    };

    onClickUpdate = async (e) => {
        e.preventDefault();
        const { name, surname, authority, email } = this.state;

        await axios.put(API + `/user/update/${this.context.userId}`, {
            name,
            surname,
            email,
            authority
        }).then((res) => {
            this.setState({
                responseMessage: res.data.successMessage,
                responseMessageClass: "alert alert-success"
            });
            this.props.setUserAuthority(authority)
        })
            .catch((err) => {
                if (err.response) {
                    if (err.response.data.errorMessage) {
                        this.setState({
                            responseMessage: err.response.data.errorMessage,
                            responseMessageClass: "alert alert-danger"
                        })
                    }
                }
            })
    };

    render() {
        const { name, surname, authority, userName, email, responseMessage, responseMessageClass } = this.state;

        return (

            <div className="formPage">

                <form onSubmit={this.onClickUpdate}>
                    <div className="header-part">
                        <h1>Kişisel Bilgilerim</h1>
                    </div>
                    <p>
                        <br />
                        Aşağıda size ait bilgiler bulunmaktadır. Lütfen değişiklik yapmak isterseniz, bilgileri eksiksiz bir şekilde doldurunuz ve "Bilgilerimi Güncelle" butonuna basınız.
                        Teşekkürler!</p>
                    <hr />

                    <fieldset>
                        <legend >Kişisel Bilgilerim</legend>

                        {
                            responseMessage ? <div className={responseMessageClass + " p-2"} role="alert">
                                {responseMessage}
                            </div> : null
                        }

                        <div className="item">
                            <label htmlFor="name">Adınız ve Soyadınız <span>*(Zorunlu)</span></label>
                            <div className="name-item">
                                <input id="name" type="text" name="name" placeholder="Adınız"
                                    onChange={this.onChangeInput} value={name} required />
                                <input id="name" type="text" name="surname" placeholder="Soyadınız"
                                    onChange={this.onChangeInput} value={surname} required />
                            </div>
                        </div>

                        <div className="item">
                            <label htmlFor="userName">Kullanıcı Adınız</label>
                            <input id="userName" type="text" name="userName"
                                onChange={this.onChangeInput} value={userName} disabled required />
                        </div>

                        <div className="item">
                            <label htmlFor="email">Email <span>*(Zorunlu)</span></label>
                            <input id="email" type="email" name="email" placeholder='Email adresini giriniz'
                                onChange={this.onChangeInput} value={email} required />
                        </div>

                        {
                            this.props.userAuthority === "Manager" ?
                                <div className="item">
                                    <label htmlFor="authority">Yetki <span>*(Zorunlu)</span></label>
                                    <select className='py-2' id="authority" name="authority"
                                        onChange={this.onChangeAuthority} value={authority}>
                                        <option value="Manager">Yönetici</option>
                                        <option value="User">Kullanıcı</option>
                                    </select>
                                </div> : null
                        }

                    </fieldset>
                    <FormPageButton buttonName="BİLGİLERİMİ GÜNCELLE" />
                </form>
            </div>
        )
    }
}

const mapStateToProps = createStructuredSelector({
    userAuthority: selectUserAuthority(),
});

const mapDispatchToProps = dispatch => (
    (
        bindActionCreators({ setUserAuthority }, dispatch)
    )
);

export default connect(mapStateToProps, mapDispatchToProps)(MyInformation);