import { Route, Routes } from 'react-router-dom';
import HomePage from './Components/HomePage/HomePage';
import Login from './Components/Login/Login';
import axios from 'axios';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectIsLogin, selectUserAuthority } from './Store/selectors';
import InNotFound from './Components/NotFound/InNotFound';
import NotInNotFound from './Components/NotFound/NotInNotFound';
import MyInformation from './Components/MyInformation/MyInformation';
import UserList from './Components/Users/UserList/UserList';
import UserUpdate from './Components/Users/UserUpdate/UserUpdate';
import AddUser from './Components/Users/AddUser/AddUser';
import TrainList from './Components/Trains/TrainList/TrainList';
import AddTrain from './Components/Trains/AddTrain/AddTrain';
import TrainUpdate from './Components/Trains/TrainUpdate/TrainUpdate';
import MaintenanceList from './Components/Maintenances/MaintenancesList/MaintenanceList';
import MaintenanceAdd from './Components/Maintenances/MaintenanceAdd/MaintenanceAdd';
import MaintenanceUpdate from './Components/Maintenances/MaintenanceUpdate/MaintenanceUpdate';
import PastMaintenancesList from './Components/PastMaintenances/PastMaintenancesList/PastMaintenancesList';
import { useContext, useEffect } from 'react';
import UserContext from './context/UserContext';
import { bindActionCreators } from 'redux';
import { setUserAuthority } from './Store/actions';
import { API } from './API/Api';
import ChangePassword from './Components/ChangePassword/ChangePassword';
import Filter from './Components/Filter/Filter';
import Documents from './Components/Documents/Documents';

axios.defaults.withCredentials = true;

function App(props) {
  const { userId, getUserId } = useContext(UserContext);

  useEffect(() => {
    if (!userId) {
      return;
    }

    const getUser = async () => {
      await getUserId();

      await axios.get(API + `/user/detail/${userId}`)
        .then(async (res) => {
          await props.setUserAuthority(res.data.authority)
        });
    };

    getUser();
  },[])

  return (
    
      <Routes >
        {
          props.isLogin ?
            <>
              <Route path="/" element={<HomePage />} />
              <Route path="login" element={<Login />} />
              <Route path="my-information" element={<MyInformation />} />
              <Route path="maintenance/list" element={<MaintenanceList />} />
              <Route path="maintenance/add" element={<MaintenanceAdd />} />
              <Route path="maintenance/update/:id" element={<MaintenanceUpdate />} />
              <Route path="past-maintenances/list" element={<PastMaintenancesList />} />
              <Route path="change-password" element={<ChangePassword />} />
              <Route path="filter-past-maintenance" element={<Filter />} />
              <Route path="documents" element={<Documents />} />

              {
                props.userAuthority === "Manager" ? <>
                  <Route path="train/list" element={<TrainList />} />
                  <Route path="train/add" element={<AddTrain />} />
                  <Route path="train/update/:id" element={<TrainUpdate />} />
                  <Route path="users/list" element={<UserList />} />
                  <Route path="users/add" element={<AddUser />} />
                  <Route path="user/update/:id" element={<UserUpdate />} />
                </> : null
              }
              <Route path='*' element={<InNotFound />} />
            </>
            :
            <>
              <Route path="login" element={<Login />} />
              <Route path='*' element={<NotInNotFound />} />
            </>
        }

      </Routes>
  );
}

const mapStateToProps = createStructuredSelector({
  isLogin: selectIsLogin(),
  userAuthority: selectUserAuthority()
});

const mapDispatchToProps = (dispatch) => (
  (
    bindActionCreators({setUserAuthority},dispatch)
  )
)

export default connect(mapStateToProps,mapDispatchToProps)(App);
