import React, { Component } from 'react'
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectPastMaintenanceFilterList } from '../../Store/selectors';
import PastMaintenancesDetailBody from '../PastMaintenances/PastMaintenanceDetail/PastMaintenancesDetailBody';
import { MDBDataTable } from 'mdbreact';

class AfterFilterList extends Component {

    onDateFormat = date => {
        return date.split("-").reverse().join("-")
    };

    render() {
        const data = {
            columns: [
                {
                    label: 'Trenin Adı',
                    field: 'trainName',
                    sort: 'asc',
                },
                {
                    label: 'Kişi Adı',
                    field: 'userName',
                    sort: 'asc',
                },
                {
                    label: 'Service Bakım Tarihi',
                    field: 'service_Maintenance_Date',
                    sort: 'asc',
                },
                {
                    label: 'KB1 Bakım Tarihi',
                    field: 'kb1_Maintenance_Date',
                    sort: 'asc',
                },
                {
                    label: 'KB2 Bakım Tarihi',
                    field: 'kb2_Maintenance_Date',
                    sort: 'asc',
                },
                {
                    label: 'KB3 Bakım Tarihi',
                    field: 'kb3_Maintenance_Date',
                    sort: 'asc',
                },
                {
                    label: 'İşlemler',
                    field: 'operations',
                    sort: 'asc',
                },
            ],
            rows: this.props.pastMaintenanceFilterList.map(pastMaintenance => {
                const { onDateFormat } = this;
                const { trainInformation, userInformation, maintenancesContents } = pastMaintenance;

                return {
                    trainName: trainInformation[0].trainName,
                    userName: userInformation[0].name + " " + pastMaintenance.userInformation[0].surname,
                    kb1_Maintenance_Date: onDateFormat(maintenancesContents[0].date),
                    kb2_Maintenance_Date: onDateFormat(maintenancesContents[1].date),
                    kb3_Maintenance_Date: onDateFormat(maintenancesContents[2].date),
                    service_Maintenance_Date: onDateFormat(maintenancesContents[3].date),
                    operations: <>
                        <button className='btn update-button' data-bs-toggle="modal" data-bs-target={"#o" + pastMaintenance._id}>
                            Detay
                        </button>
                        <PastMaintenancesDetailBody maintenance={pastMaintenance} />
                    </>
                }
            })
        };
        return (

            <MDBDataTable
                entriesOptions={[5, 10, 25]}
                hover
                striped
                bordered
                data={data}
                searchLabel='Filtrele'
                entrieslabel=' '
                infoLabel={["", "-", "sonuç", ""]}
                paginationLabel={["Geri", "İleri"]}
                noRecordsFoundLabel='Sonuç bulunamadı'
                noBottomColumns={true}
            />

        )
    }
}

const mapStateToProps = createStructuredSelector({
    pastMaintenanceFilterList: selectPastMaintenanceFilterList()
});

export default connect(mapStateToProps)(AfterFilterList);