import React from 'react'
import Footer from '../../Footer/Footer';
import Header from '../../Header/Header';
import Navbar from '../../Navbar/Navbar';
import TrainUpdateBody from './TrainUpdateBody';

function TrainUpdate() {
  return (
    <>
        <Navbar />
        <Header />
        <TrainUpdateBody />
        <Footer />
    </>
  )
}
export default TrainUpdate;