import React from 'react'
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import Navbar from '../Navbar/Navbar';
import ChangePasswordBody from './ChangePasswordBody';

function ChangePassword() {
  return (
    <>
        <Navbar />
        <Header />
        <ChangePasswordBody />
        <Footer />
    </>
  )
}
export default ChangePassword;