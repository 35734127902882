import React, { Component } from 'react'
import { slide as Menu } from 'react-burger-menu';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectUserAuthority } from '../../Store/selectors';
import { Link } from 'react-router-dom';

class Navbar extends Component {

  render() {

    return (
      <Menu {...this.props}>
        <Link to="/">
          Ana Sayfa
        </Link>

        <Link to="/my-information">
          Bilgilerim
        </Link>
        {
          this.props.userAuthority === "Manager" ?

            <Link to="/users/list">
              Kullanıcılar
            </Link>
            : null
        }


        {
          this.props.userAuthority === "Manager" ?

            <Link to="/train/list">
              Trenler
            </Link>
            : null
        }

        <Link to="/maintenance/list">
          Bakımlar
        </Link>

        <Link to="/past-maintenances/list">
          Geçmiş Bakımlar
        </Link>

        <Link to="/filter-past-maintenance">
          Geçmiş Bakım Filtreleme
        </Link>
        <Link to="/documents">
          Dökümanlar
        </Link>

        <Link to="/change-password">
          Şifre Değiştir
        </Link>

        <Link to="/login" >
          Çıkış Yap
        </Link>
      </Menu>
    )
  }
}

const mapStateToProps = createStructuredSelector({
  userAuthority: selectUserAuthority(),
});

export default connect(mapStateToProps)(Navbar);