import axios from 'axios';
import React, { useState, useRef, useContext } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as api from '../../../API/Api';
import { setIsLogin } from '../../../Store/actions';
import { useNavigate } from 'react-router-dom'
import UserContext from '../../../context/UserContext';
import TMS from '../../../Images/tms-logo.png';

function SignIn(props) {

  let userName = useRef();
  let password = useRef();
  const [faultMessage, setFaultMessage] = useState("");
  let navigate = useNavigate();

  const { getUserId } = useContext(UserContext);

  const handleKeypress = e => {
    if (e.keyCode === 13) {
      onClickSignIn();
    }
  };

  const onClickSignIn = async (e) => {
    e.preventDefault();

    const signInData = {
      userName: userName.current.value.trim(),
      password: password.current.value.trim()
    };

    await axios.post(api.API + "/authentication/login", signInData).then(async () => {

      await props.setIsLogin(true);

      await getUserId()
        .then(() => {
          navigate("/")
        })
        .catch(() => setFaultMessage(
          <div className="alert alert-danger text-center" role="alert">
            Bir sorun ile karşılaşıldı.
          </div>
        ))

    }).catch(err => {
      if (err.response) {
        if (err.response.data.errorMessage) {
          setFaultMessage(
          <div className="alert alert-danger text-center" role="alert">
            {err.response.data.errorMessage}
          </div>
          )
        }
      }
    })
  };

  return (

    <div className="sign-in">
      <form onSubmit={onClickSignIn}>

        <label htmlFor="chk" aria-hidden="true">
          Giriş Yap
        </label>
        <input type="text" name="userName" ref={userName} placeholder="Kullanıcı Adı" onKeyPress={handleKeypress} required />
        <input type="password" name="pswd" ref={password} placeholder="Şifre" onKeyPress={handleKeypress} required />
        {
          faultMessage ? faultMessage : null
        }
        <button type='submit'>Giriş Yap</button>
      </form>
      <div className='logo-div'>
        <img src={TMS} alt="loginimg" />
      </div>
    </div>

  )
}

const mapDispatchToProps = dispatch => (
  (
    bindActionCreators({ setIsLogin }, dispatch)
  )
)

export default connect(null, mapDispatchToProps)(SignIn);