import React from 'react'
import Header from '../Header/Header';
import Navbar from '../Navbar/Navbar';
import MyInformationBody from './MyInformationBody';

function MyInformation() {

  return (
    <div>

      <Navbar />
      <Header />
      <MyInformationBody />
      
    </div>
  )
}
export default MyInformation;