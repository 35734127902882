import React, { Component } from 'react';
import TMS from '../../Images/tms-logo.png';
import { Link } from 'react-router-dom';

class Header extends Component {
    render() {
        return (
            <div className='header'>
                <Link to="/">
                    <img src={TMS} alt="TMS Logo" title="TMS Logo" />
                </Link>
            </div>
        )
    }
}

export default Header;