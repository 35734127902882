import axios from 'axios';
import React, { Component } from 'react';
import { API } from '../../API/Api';
import UserContext from '../../context/UserContext';
import FormPageButton from '../FormPagesCommon/FormPageButton';
import FormPageHeader from '../FormPagesCommon/FormPageHeader';

class ChangePasswordBody extends Component {
    
    static contextType = UserContext;

    state = {
        "oldPassword": "",
        "newPassword": "",
        "newPasswordVerify": "",
        "responseMessage": "",
        "responseMessageClass": "",
    }

    componentDidMount = async () => {
        await this.context.getUserId;
    }

    onChangeInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
            "responseMessage": "",
            "responseMessageClass": "",
        })
    }

    onClickSave = async (e) => {

        e.preventDefault();

        const { oldPassword, newPassword, newPasswordVerify } = this.state;

        await axios.put(API + `/user/password-change/${this.context.userId}`, {
            oldPassword,
            newPassword,
            newPasswordVerify
        })
            .then(response => {
                this.setState({
                    oldPassword: "",
                    newPassword: "",
                    newPasswordVerify:"",
                    responseMessage: response.data.successMessage,
                    responseMessageClass: "alert alert-success"
                })
            })
            .catch(error => {
                if (error.response)
                    if (error.response.data.errorMessage) {
                        this.setState({
                            responseMessage: error.response.data.errorMessage,
                            responseMessageClass: "alert alert-danger"
                        })
                    }
            })
    }

    render() {
        const { oldPassword, newPassword, newPasswordVerify, responseMessage, responseMessageClass } = this.state;
        return (
            <div className="formPage">

                <form onSubmit={this.onClickSave}>
                    <FormPageHeader header="Şifremi Değiştir" />
                    <p>
                        <br />
                        Lütfen aşağıda ki bilgileri eksiksiz doldurunuz ardından "Şifremi Değiştir" butonuna basınız.
                        Teşekkürler!</p>
                    <hr />

                    <fieldset>
                        <legend >Şifremi Değiştir</legend>

                        {
                            responseMessage ? <div className={responseMessageClass + " p-2"} role="alert">
                                {responseMessage}
                            </div> : null
                        }

                        <div className="item">
                            <label htmlFor="oldPassword">Eski Şifre:  <span>*(Zorunlu)</span></label>
                            <input id="oldPassword" type="password" name="oldPassword" placeholder="Şu an ki şifrenizi giriniz."
                                onChange={this.onChangeInput} value={oldPassword} required />
                        </div>

                        <div className="item">
                            <label htmlFor="newPassword">Yeni Şifre: <span>*(Zorunlu)</span></label>
                            <input id="newPassword" type="password" name="newPassword" placeholder="Yeni şifrenizi giriniz."
                                onChange={this.onChangeInput} value={newPassword} required />
                        </div>

                        <div className="item">
                            <label htmlFor="newPasswordVerify">Yeni Şifre(Tekrar): <span>*(Zorunlu)</span></label>
                            <input id="newPasswordVerify" type="password" name="newPasswordVerify" placeholder="Yeni şifrenizi tekrar giriniz."
                                onChange={this.onChangeInput} value={newPasswordVerify} required />
                        </div>

                    </fieldset>

                    <FormPageButton buttonName="ŞİFREMİ DEĞİŞTİR" />
                </form>
            </div>
        )
    }
}
export default ChangePasswordBody;