import React from 'react'
import Footer from '../../Footer/Footer';
import Header from '../../Header/Header';
import Navbar from '../../Navbar/Navbar';
import TrainListBody from './TrainListBody';

function TrainList() {
  return (
    <>
        <Navbar />
        <Header />
        <TrainListBody />
        <Footer />
    </>
  )
}
export default TrainList;