import React from 'react'

function PopupMaintenanceCard({name, date, km, explaining}) {
    return (
        <div>
            <h4 className='text-center'> {name} </h4>
            <p>
                <b>Bakım Tarihi: </b>
                {date ? date.split("-").reverse().join("-"):"-"}
            </p>
            <p>
                <b>Bakım Km: </b>
                {km ? km.replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " km":"-"}
            </p>
            <p>
                <b>Açıklama: </b>
                {explaining ? explaining:"-"}
            </p>
        </div>
    )
}
export default PopupMaintenanceCard;