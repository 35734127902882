import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import reducers from './Store/reducers';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { configureStore } from '@reduxjs/toolkit';
// import {combineReducers} from "redux"; 
import thunk from 'redux-thunk';
import { UserContextProvider } from './context/UserContext';
import './index.scss';
// const reducers = combineReducers({
//     reducer
//  });

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['isLogin', 'userAuthority', 'pastMaintenanceFilterList'],
  blacklist: [],
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk]
});

let persistor = persistStore(store);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(

  <Provider store={store}>
    <PersistGate loading={<div>Loading</div>} persistor={persistor}>
      <BrowserRouter>
        <UserContextProvider>
          <App />
        </UserContextProvider>
      </BrowserRouter>
    </PersistGate>
  </Provider>

);

reportWebVitals();
